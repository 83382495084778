import { MetafieldTypes, type UnlikelyMetafield } from "@unlikelystudio/commerce-connector"

import type { AllValues, ObjectValues } from "~/@types/generics"
import type { I18nLang } from "~/lib/i18n/types"
import { createMetafieldInput } from "~/lib/shopify/metafields/utils/create-metafield-input"

export type Metafields = Record<I18nLang | "universal", { [key: string]: string }>

export const collectionMetafields = {
  universal: {
    breadcrumbThemeColor: "custom.breadcrumb_theme_color",
    collectionLink: "custom.collection_link",
    pushTitle: "custom.push_title",
    pushDescription: "custom.push_description",
    pushImage: "custom.push_image",
    pushButtonLabel: "custom.push_button_label",
    pushTheme: "custom.push_theme",
    collectionTemplateGrid: "custom.collection_template_grid",
    forceLoginOnMarkets: "custom.collection_behind_login_in_markets",
    hiddenProductTags: "custom.collection_tags_to_hide",
    titleFont: "custom.title_font",
    loginBannerPosition: "custom.login_banner_position",
    cardImagesRhythm: "collection.card_images_rhythm",
    noIndex: "collection.no_index",
  },
  fr: {},
  en: {},
} as const satisfies Metafields

export const productVariantMetafields = {
  universal: {
    priceMarketsSales: "product_variant.price_markets_sales",
  },
  fr: {},
  en: {},
} as const satisfies Metafields

export const productMetafields = {
  universal: {
    apparel: "unlikely.apparel", // added in pim v2
    cardTags: "erp.custom_card_tags",
    no_size_guide: "product.no_size_guide",
    primaryColor: "product.primary_color",
    productSiblings: "unlikely.product_siblings", // added in pim v2
    ref: "unlikely.groupId",
    reSku: "erp.reSku",
    shop_the_look: "product.shop_the_look",
    template: "BACK.template_type",
    traceability: "custom.tracabilite",
    videoCollection: "custom.collection_video",
    videoPriorityOnCollection: "custom.video_priority_on_collection",
    // TODO: delete group mtc?
    groupMtc: "product.group_mtc",
    composition: "unlikely.composition",
    care: "unlikely.care",

    // WARNING UNUSED INTO CODEBASE
    category: "unlikely.apparel",
    filterColor: "unlikely.filter_color",
    cross_sell: "FRONT.cross_sell_products",
    filterColorHexa: "erp.filter_color_hexa",
  },
  fr: {},
  en: {},
} as const satisfies Metafields

export const customerMetafields = {
  universal: {
    gender: "customer.gender",
    birthday: "customer.birthday",
  },
} as const satisfies Metafields
type CustomerMetafieldsKeys = ObjectValues<typeof customerMetafields>
export type CustomerMetafieldsProcessedKeys = AllValues<CustomerMetafieldsKeys>

type ProductVariantMetafieldsKeys = ObjectValues<typeof productVariantMetafields>
export type ProductVariantMetafieldsProcessedKeys = AllValues<ProductVariantMetafieldsKeys>

export type MetafieldInput = UnlikelyMetafield & {
  processedKey: string
}

type MetafieldInputs = Record<string, Omit<MetafieldInput, "ownerId" | "value">>

export const METAFIELD_DEFAULT_VALUE = "unspecified"

export const METAFIELD_INPUTS = {
  CUSTOMER_BIRTHDAY: createMetafieldInput<CustomerMetafieldsProcessedKeys>(
    "customer.birthday",
    MetafieldTypes.single_line_text_field
  ),
  CUSTOMER_GENDER: createMetafieldInput<CustomerMetafieldsProcessedKeys>(
    "customer.gender",
    MetafieldTypes.single_line_text_field
  ),
  PRODUCT_VARIANT_PRICE_MARKETS_SALES: createMetafieldInput<ProductVariantMetafieldsProcessedKeys>(
    "product_variant.price_markets_sales",
    MetafieldTypes.json
  ),
} satisfies MetafieldInputs
