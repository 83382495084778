import type { formDictionary } from "@unlikelystudio/react-ecommerce-hooks"

import type { ObjectKeys } from "~/@types/generics"
import { objectValues } from "~/utils/object-values"

export type FormDictionnaryLang = ObjectKeys<typeof formDictionary>
export type FormDictionnaryKey = keyof (typeof formDictionary)[FormDictionnaryLang]

export const ALERT_CODES = {
  SERVER_ERROR: "SERVER_ERROR",
  ENTITY_SAVED: "ENTITY_SAVED",
  ALREADY_ENABLED: "ALREADY_ENABLED",
  BAD_DOMAIN: "BAD_DOMAIN",
  BLANK: "BLANK",
  NEWSLETTER_SUBSCRIBE: "NEWSLETTER_SUBSCRIBE",
  CONTAINS_HTML_TAGS: "CONTAINS_HTML_TAGS",
  CONTAINS_URL: "CONTAINS_URL",
  COPY_TO_CLIPBOARD: "COPY_TO_CLIPBOARD",
  CUSTOMER_DISABLED: "CUSTOMER_DISABLED",
  CLIPBOARD: "CLIPBOARD",
  CART_DOES_NOT_MEET_DISCOUNT_REQUIREMENTS_NOTICE: "CART_DOES_NOT_MEET_DISCOUNT_REQUIREMENTS_NOTICE",
  CUSTOMER_ALREADY_USED_ONCE_PER_CUSTOMER_DISCOUNT_NOTICE: "CUSTOMER_ALREADY_USED_ONCE_PER_CUSTOMER_DISCOUNT_NOTICE",
  DISCOUNT_ALREADY_APPLIED: "DISCOUNT_ALREADY_APPLIED",
  DISCOUNT_CODE_APPLICATION_FAILED: "DISCOUNT_CODE_APPLICATION_FAILED",
  DISCOUNT_DISABLED: "DISCOUNT_DISABLED",
  DISCOUNT_EXPIRED: "DISCOUNT_EXPIRED",
  CONTACT_FORM: "CONTACT_FORM",
  DISCOUNT_LIMIT_REACHED: "DISCOUNT_LIMIT_REACHED",
  DISCOUNT_NOT_FOUND: "DISCOUNT_NOT_FOUND",
  FORM_SUCCESS: "FORM_SUCCESS",
  INVALID: "INVALID",
  INVALID_MULTIPASS_REQUEST: "INVALID_MULTIPASS_REQUEST",
  LOGGED_IN: "LOGGED_IN",
  NOT_FOUND: "NOT_FOUND",
  PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE: "PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE",
  PRODUCT_IN_CART: "PRODUCT_IN_CART",
  PROMOCODE_INVALID: "PROMOCODE_INVALID",
  PROMOCODE_PRODUCT_INVALID: "PROMOCODE_PRODUCT_INVALID",
  PROMOCODE_REMOVE_ERROR: "PROMOCODE_REMOVE_ERROR",
  PROMOCODE_NOT_LOGGED_IN: "PROMOCODE_NOT_LOGGED_IN",
  TAKEN: "TAKEN",
  TOKEN_INVALID: "TOKEN_INVALID",
  TOO_LONG: "TOO_LONG",
  TOO_SHORT: "TOO_SHORT",
  EMAIL_SENT: "EMAIL_SENT",
  RESET_PASSWORD_EMAIL_SENT: "RESET_PASSWORD_EMAIL_SENT",
  REMOVE_WISHLIST: "REMOVE_WISHLIST",
  PASSWORD_RESET: "PASSWORD_RESET",
  SHOP_THE_LOOK_UNAVAILABLE: "SHOP_THE_LOOK_UNAVAILABLE",
  UNIDENTIFIED_CUSTOMER: "UNIDENTIFIED_CUSTOMER",
  GOOGLE_RECAPTCHA_ERROR: "GOOGLE_RECAPTCHA_ERROR",
  BACK_IN_STOCK_ALREADY_SUBSCRIBED: "BACK_IN_STOCK_ALREADY_SUBSCRIBED",
  EMAIL_ALREADY_SUBSCRIBED: "EMAIL_ALREADY_SUBSCRIBED",
} as const

export const ALERT_PRESETS_VALUES = objectValues(ALERT_CODES)

export type AlertPresetValues = (typeof ALERT_CODES)[keyof typeof ALERT_CODES]
export type AlertPresetKeys = keyof typeof ALERT_CODES
